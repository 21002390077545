import type { FC } from "react"
import { memo, useMemo } from "react"
import Head from "next/head"
import { useRouter } from "next/router"
import { useCanonical } from "@/hooks/useCanonical"
import { FAVICON, SITE_URL, TITLE_SITE_RU } from "@/utils/constants"
import { META_DESCRIPTION_DEFAULT, META_TITLE_DEFAULT } from "./constants"

export type MetaEntityType = {
  content?: string
  isVisible?: boolean
}

export type MetaPropsType = {
  title?: string
  description?: string | MetaEntityType
  ogTags?: {
    description?: string
    title?: string
    url?: string
    image?: string
  }
}

const Meta: FC<MetaPropsType> = memo(
  ({ title = META_TITLE_DEFAULT, description, ogTags, children }) => {
    const { canonical: canonicalUrl } = useCanonical()
    const router = useRouter()

    const ogImage = ogTags?.image || `${SITE_URL}/${FAVICON["256"]}`

    const ogUrl = ogTags?.url || `${SITE_URL}${router.asPath}`

    const {
      isVisible: descrIsVisible,
      content: descrContent,
    }: Required<MetaEntityType> = useMemo(() => {
      if (!description) {
        return {
          content: META_DESCRIPTION_DEFAULT,
          isVisible: true,
        }
      }

      if (typeof description === "string") {
        return {
          content: description,
          isVisible: true,
        }
      }

      return {
        content: description.content || META_DESCRIPTION_DEFAULT,
        isVisible: !!description.isVisible,
      }
    }, [description])

    return (
      <>
        <Head>
          <title>{title}</title>
          {descrIsVisible && <meta name="description" content={descrContent} />}
          <meta property="og:type" content={"website"} />
          <meta property="og:site_name" content={TITLE_SITE_RU} />
          <meta
            property="og:description"
            content={ogTags?.description || descrContent}
          />
          <meta property="og:title" content={ogTags?.title || title} />
          <meta property="og:url" content={ogUrl} />
          <meta property="og:image" content={ogImage} />
          <link rel="canonical" href={canonicalUrl} />
        </Head>
        <>{children}</>
      </>
    )
  },
)

Meta.displayName = "Meta"

export { Meta }
